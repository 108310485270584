var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"library-management",attrs:{"id":"content"}},[_c('div',{staticClass:"bar-actions"}),_c('div',{staticClass:"form-container"},[_c('div',{staticClass:"form"},[_c('div',{staticClass:"title-type"},[_vm._v(_vm._s(_vm.$t('library.type_selector_title')))]),_c('div',{staticClass:"boxes"},[_c('div',{staticClass:"box-container"},[_c('div',{staticClass:"box",style:({
                            backgroundImage: 'url(' + _vm.css_image_url + 'image_neutro.svg)',
                            backgroundSize: '58px'
                        }),on:{"click":function($event){return _vm.type('image')}}}),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('library.image')))])]),_c('div',{staticClass:"box-container"},[_c('div',{staticClass:"box",style:({
                            backgroundImage: 'url(' + _vm.css_image_url + 'video_neutro.svg)',
                            backgroundSize: '58px'
                        }),on:{"click":function($event){return _vm.type('video')}}}),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('library.video')))])]),_c('div',{staticClass:"box-container"},[_c('div',{staticClass:"box",style:({
                            backgroundImage: 'url(' + _vm.css_image_url + 'file_neutro.svg)',
                            backgroundSize: '58px'
                        }),on:{"click":function($event){return _vm.type('file')}}}),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('library.file')))])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }