<template>
    <div id="content" class="library">
        <template v-if="form">
            <Form :id="libraryID" @back="closeForm"></Form>
        </template>
        <template v-else>
            <div class="templates-top-bar-actions">
                <div class="left-menu-container">
                    <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                </div>
                <Button :customClass="{ disabled: !hasAccess }" bType="create" :bCallback="addLibrary" bLabel="library.add_title_button"></Button>
            </div>

            <div class="library-container supervise-container" :class="{ 'with-trial': trialEndDate }">
                <!-- sidebar filters -->
                <div class="sidebar-filters" :class="{ 'empty-results': noChanges && Object.values(items).length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden }">
                    <FilterLibrary :isDisabled="noChanges && Object.values(items).length == 0" @noChanges="noChanges = true" @changes="noChanges = false" @resetPageDatatable="top(true)"></FilterLibrary>
                </div>
                <!-- table -->
                <div class="data-table" :class="{ 'empty-results': noChanges && Object.values(items).length == 0 }">
                    <v-data-table v-if="items" v-model="selected" :headers="headers" fixed-header :items="Object.values(items)" sort-by :sort-asc="true" hide-default-footer :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pageCount = $event">
                        <!-- Category -->
                        <template #item.category_id="item">
                            <div @click="goToReport(item.item.id)">
                                <div class="final-name" v-html="getFinalName(item.item)"></div>
                            </div>
                        </template>
                        <!-- Preview -->
                        <!-- <template #item.preview="item">
                            <div v-if="item.item.preview != null" class="image" :style="{ backgroundImage: 'url(' + item.item.preview + ')' }" @click="openMedia(item.item.preview)"></div>
                            <div
                                v-else
                                class="image"
                                :class="{
                                    'image-default': item.item.type == 1,
                                    video: item.item.type == 2,
                                    file: item.item.type == 3
                                }"
                            ></div>
                        </template> -->
                        <template #item.preview="item">
                            <div class="image" @click="openMedia(item.item.preview)">
                                <cld-image v-if="item.item.preview != null" class="image" :publicId="item.item.preview" type="fetch" loading="lazy">
                                    <cld-transformation height="300" radius="3" crop="fill" fetchFormat="auto" />
                                </cld-image>
                                <div
                                    v-else
                                    class="image"
                                    :class="{
                                        'image-default': item.item.type == 1,
                                        video: item.item.type == 2,
                                        file: item.item.type == 3
                                    }"
                                ></div>
                            </div>
                        </template>

                        <!-- Name -->
                        <template #item.name="item">
                            <div class="name" @click="goToReport(item.item.id)">{{ item.item.name }}</div>
                        </template>

                        <!-- TRANSLATIONS -->
                        <template #item.translations="item" v-if="organizationRender.availableLangs">
                            <div @click="goToReport(item.item.id)">
                                <template v-if="!getTranslations(item.item.translations)">
                                    <!-- {{ $t('multi-language.no_translated') }} -->
                                </template>
                                <template v-else>
                                    <Tags :tags="getTranslations(item.item.translations)" :nameTag="'name'" value="name" :backColor="'backColor'"></Tags>
                                </template>
                            </div>
                        </template>

                        <!-- upload_date -->
                        <template #item.upload_date="item">
                            <template v-if="item.item.upload_date">
                                <div @click="goToReport(item.item.id)" class="capitalize">
                                    {{ formatRelative(item.item.upload_date, false) }}
                                </div>
                            </template>
                        </template>

                        <!-- highlight -->
                        <template #item.highlight="item">
                            <div @click="goToReport(item.item.id)" class="highlight" :class="{ yes: item.item.highlight == 1 }"></div>
                        </template>

                        <!-- Status -->
                        <template #item.status="item">
                            <div v-if="item.item.status" class="status" @click="goToReport(item.item.id)">
                                <span class="estate">
                                    <span
                                        class="ball-color"
                                        :class="{
                                            active: item.item.status == '1',
                                            pendent: item.item.status == '2',
                                            inactive: item.item.status == '-2',
                                            programmed: isAfterToday(item.item.start_date ? item.item.start_date : false)
                                        }"
                                    ></span>
                                    <span v-if="item.item.status == '1' && !isAfterToday(item.item.start_date ? item.item.start_date : false)">{{ $t('sites.form.active') }}</span>
                                    <span v-else-if="item.item.status == '-2'">{{ $t('sites.form.inactive') }}</span>
                                    <span v-else-if="item.item.status == '2'">{{ $t('library.upload_pending') }}</span>
                                    <span v-else-if="isAfterToday(item.item.start_date ? item.item.start_date : false)">{{ $t('library.scheduled') }}</span>
                                </span>

                                <span class="programed" v-if="item.item.start_date && item.item.end_date">
                                    {{ $t('library.since') }}
                                    {{ formatDate(item.item.start_date) }}
                                    {{ $t('library.to') }}
                                    {{ formatDate(item.item.end_date) }}
                                </span>
                                <span class="programed" v-else-if="item.item.start_date && item.item.end_date == false">{{ $t('library.from') }} {{ formatDate(item.item.start_date) }}</span>
                                <span class="programed" v-else-if="item.item.start_date == false && item.item.end_date">{{ $t('library.until') }} {{ formatDate(item.item.end_date) }}</span>
                                <span v-else class="programed"></span>
                            </div>
                        </template>

                        <!-- No Data -->
                        <template v-slot:no-data>
                            <div class="no-data">
                                {{ $t('supervise.checklists.table_headers.no_data') }}
                            </div>
                        </template>
                    </v-data-table>
                    <div id="pagination" v-if="!(noChanges && Object.values(items).length == 0)">
                        <div class="totalItems">
                            <span class="total">
                                <strong class="total-results">{{ Object.values(items).length }}</strong>
                                {{ $t('supervise.checklists.table_headers.results') }}
                            </span>
                        </div>
                        <v-pagination v-model="pagination.page" @input="top(false)" color="#2c52c4" :length="pageCount" :total-visible="maxPageVisibles"></v-pagination>
                        <div class="rows-page">
                            <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                        </div>
                    </div>
                    <div class="andy-datatable-empty" v-if="noChanges && itemsLoaded && Object.values(items).length == 0">
                        <EmptyTable @buttonAction="addLibrary" :buttonText="$t('library.add_title_button')" :title="$t('empty_table.users_title')" :description="$t('empty_table.library_empty')"></EmptyTable>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import i18n from '@/i18n'
import FilterLibrary from '@/components/domain/library/filter'
import Form from '@/components/domain/library/form'
import EmptyTable from '@/components/ui/EmptyTable'

export default {
    components: {
        FilterLibrary,
        Form,
        EmptyTable
    },
    name: 'Library',
    data() {
        return {
            noChanges: false,
            css_image_url: '/../../img/',
            libraryID: undefined,
            form: false,
            values: false,
            singleSelect: false,
            valueFilter: 1,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    align: 'start',
                    sortable: false,
                    value: 'preview',
                    width: '30px',
                    class: 'header-table'
                },
                {
                    text: this.$t('library.name'),
                    align: 'start',
                    width: '380px',
                    sortable: true,
                    value: 'name',
                    class: 'header-table'
                },
                {
                    text: this.$t('library.category'),
                    align: 'start',
                    width: '380px',
                    sortable: true,
                    value: 'category_id',
                    class: 'header-table'
                },
                {
                    text: this.$t('library.last_update'),
                    align: 'start',
                    sortable: true,
                    width: '50px',
                    sort: this.sortByDates,
                    value: 'upload_date',
                    class: 'header-table'
                },
                {
                    text: this.$t('library.highlight'),
                    align: 'start',
                    width: '150px',
                    sortable: true,
                    value: 'highlight',
                    class: 'header-table'
                },
                {
                    text: this.$t('library.status'),
                    align: 'start',
                    sortable: false,
                    value: 'status',
                    width: '150px',
                    class: 'header-table'
                }
            ],
            filtersSidebarIsHidden: false
        }
    },
    computed: {
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        itemsLoaded() {
            return this.$store.getters['library/getItemsLoaded']
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        items() {
            return this.$store.getters['library/getLibrary']()
        },
        hasAccess() {
            return this.checkPermission('/library/add')
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },
        organizationRender() {
            var org = this.$store.getters['account/getOrganization']

            if (org && org.account && org.languages) {
                var account = org.account
                // var languages = org.languages
                var availableTranslationLanguages = account.config.languages.split(',') != '' ? account.config.languages.split(',') : false

                var result = {
                    allLangs: org.languages,
                    accountLanguage: org.languages[account.lang],
                    availableLangs: availableTranslationLanguages
                }
                return result
            }
            return false
        },
        categories() {
            var categories = this.$store.getters['library/getLibraryCategories']()
            var listSorted = Object.values(categories).sort(function (a, b) {
                return a.name < b.name
            })
            return categories
        }
    },
    methods: {
        getFinalName(resource) {
            let item = this.categories[resource.category_id]
            // get final name with breadcrumb searching in parents
            var finalName = ''
            let parent = item && item.parent_id ? item.parent_id : null
            //search recursively
            if (!item) {
                return "<span class='category'></span>"
            } else if (!parent) {
                return (finalName = "<span class='category'>" + item.name + '</span>')
            }
            while (parent) {
                var parentItem = this.categories[parent]
                if (parentItem) {
                    finalName = parentItem.name + ' > ' + finalName
                    parent = parentItem.parent_id ? parentItem.parent_id : null
                } else {
                    parent = null
                }
            }
            finalName = "<span class='category'>" + item.name + '</span>' + "<span class='path'>" + finalName + '<b>' + item.name + '</b>' + '</span>'
            return finalName
        },
        getTranslations(oTranslations) {
            var self = this
            if (oTranslations && Object.keys(oTranslations).length > 0) {
                return Object.keys(oTranslations).map(function (key) {
                    if (self.organizationRender.availableLangs.includes(key)) {
                        return { name: self.organizationRender.allLangs[key].name + ' <b>(' + parseInt((oTranslations[key].translated / (oTranslations[key].missing + oTranslations[key].translated)) * 100) + '%)</b>', backColor: oTranslations[key].missing > 0 ? '#f5d9bc' : '#c2f3db' }
                    }
                })
            } else return false
        },

        top(cond) {
            if (cond && !this.$route.params.noChangePageDatatable) {
                this.pagination.page = 1
            }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        formatRelative(date) {
            return formatRelative(date, false)
        },
        isAfterToday(startDate) {
            if (startDate) {
                if (moment(startDate).isAfter(moment())) {
                    return true
                }
            }
            return false
        },
        checkPermission(routePath) {
            var route = { id: 'prueba', path: routePath }
            return this.$store.getters['hasAccess'](route)
        },
        goToReport(id) {
            if (this.checkPermission('/library/idLibrary')) {
                this.form = true
                this.libraryID = id
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('library.no_permission'),
                    duration: 5000
                })
            }
        },
        addLibrary() {
            if (this.checkPermission('/library/add')) {
                this.form = true
                this.libraryID = undefined
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('library.no_permission'),
                    duration: 5000
                })
            }
        },
        closeForm() {
            this.form = false
            this.libraryID = false
            this.$router.push({
                name: 'Library',
                params: {
                    noChangePageDatatable: true
                }
            })
            this.$overlay.hide()
        },
        load() {
            var self = this
            var items = this.$store.getters['library/getLibrary']()
            var filters = this.$store.getters['library/getCategoriesResourcesFilters']()
            var categoryOptions = this.$store.getters['library/getLibraryCategories']()

            // HEADERS OF MULTI-LANGUAGE
            var translationHeader = {
                text: this.$t('multi-language.translations'),
                value: 'translations',
                align: 'start',
                width: '300px',
                class: 'header-table'
            }
            var hasTranslation = self.headers.filter((header) => {
                return header.value == 'translations'
            })

            if (!this.organizationRender) {
                this.$store.dispatch('account/loadListOrganization', {}).then(function () {
                    if (self.organizationRender.availableLangs.length > 0 && hasTranslation == 0) {
                        self.headers.splice(4, 0, translationHeader)
                    }
                })
            } else {
                if (self.organizationRender && self.organizationRender.availableLangs.length > 0 && hasTranslation.length == 0) {
                    self.headers.splice(4, 0, translationHeader)
                }
            }

            if (Object.keys(items).length > 0 && Object.keys(filters).length > 0 && Object.keys(categoryOptions).length > 0) {
                self.$store.dispatch('library/loadLibraryCategories', {}).then(function () {
                    self.$store.dispatch('library/loadLibraryCategoriesForm', { status: 1 }).then(function () {
                        // self.$store
                        //   .dispatch("library/loadLibrary", {
                        //     status: 1,
                        //     start_date: "noDate"
                        //   })
                        //   .then(function() {
                        if (self.$route.params.id) {
                            self.$store.commit('library/setCurrentResource', self.$store.getters['library/getLibrary'](self.$route.params.id))
                        }
                        if (self.$route.params.id) {
                            self.goToReport(self.$route.params.id)
                        } else if (self.$route.name == 'AddLibrary') {
                            self.addLibrary()
                        } else {
                            self.form = false
                            self.libraryID = false
                        }
                        // })
                    })
                })
            } else {
                self.$store.dispatch('library/loadLibraryCategories', { status: 1 }).then(function () {
                    self.$store.dispatch('library/loadFiltersLibraryResources', {}).then(function () {
                        self.$store.dispatch('library/loadLibraryCategoriesForm', { status: 1 }).then(function () {
                            // self.$store
                            //   .dispatch("library/loadLibrary", {
                            //     status: 1,
                            //     start_date: "noDate"
                            //   })
                            //   .then(function() {
                            // console.log('loadLibrary')
                            if (self.$route.params.id) {
                                self.$store.commit('library/setCurrentResource', self.$store.getters['library/getLibrary'](self.$route.params.id))
                            }
                            if (self.$route.params.id) {
                                self.goToReport(self.$route.params.id)
                            } else if (self.$route.name == 'AddLibrary') {
                                self.addLibrary()
                            } else {
                                self.form = false
                                self.libraryID = false
                            }
                        })
                    })
                })
            }
        },
        formatDate(value) {
            return moment(value).format('D MMM') != moment('00/00/0000').format('D MMM') ? moment(value).format('D MMM') : '-'
        },
        getCategory(id) {
            if (id) {
                var result = this.$store.getters['library/getLibraryCategories'](id)
                if (result) {
                    if (result.name) {
                        if (result.name.split('>')[1]) {
                            result.name = result.name.split('>')[1]
                        }
                    }
                }
                return result
            } else {
                return false
            }
        },
        getCategoryName(id) {
            if (id) {
                var result = this.$store.getters['library/getCategoriesForm'](id)
                if (typeof result != 'undefined') {
                    if (result.parent_id) {
                        var parent_id = this.$store.getters['library/getCategoriesForm'](result.parent_id)
                        if (result.name.includes('>')) {
                            return result.name
                        }
                        return parent_id.name + ' > ' + result.name
                    }
                    return result.name
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        getParentCategory(id) {
            var category = this.$store.getters['library/getParentsCategoryFilters']()
            if (category) {
                for (var cat in category) {
                    var idx = category[cat]
                    if (idx) {
                        if (idx.id == id) {
                            return idx
                        }
                    }
                }
            }
            return null
        },
        openMedia(ref) {
            this.$popup.media({ image: ref })
        },
        sortByDates(momentA, momentB) {
            return momentA.isAfter(momentB) ? 1 : -1
        },

        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('librarySidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        setFiltersSidebarIsHiddenValue() {
            const storedValue = localStorage.getItem('librarySidebarIsHiddenValue')
            this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
        }
    },
    watch: {
        $route(to, from) {
            // react to route changes...
            // console.log(to, from)
            this.load()
        }
    },
    created() {
        this.load()
    },
    mounted() {
        this.setFiltersSidebarIsHiddenValue()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.library {
    .library-container {
        display: flex;
        align-items: stretch;
        height: calc(100% - 100px);

        .data-table {
            .v-data-table,
            .v-data-table table {
                .date {
                    display: flex;
                    flex-direction: column;
                    .hour {
                        @include font-size($size: sm);
                        font-family: $text-bold;
                    }
                }

                .image {
                    @include background($size: cover);
                    width: 40px;
                    height: 40px;
                    border-radius: 4px;
                    margin: 0 auto;

                    &:not(.image-default):not(.video):not(.file):hover {
                        &:after {
                            background-color: rgba($color-neutral-200, 0.7);
                            background-image: img('search_neutro_s90.svg');
                        }
                    }
                    &:after {
                        @include background($size: 16px, $position: bottom 6px right 6px);
                        transition-duration: 0.2s;
                        content: '';
                        width: 40px;
                        height: 40px;
                        display: block;
                    }

                    &.image-default {
                        @include background($image: img('image_neutro.svg'));
                    }
                    &.video {
                        @include background($image: img('video_neutro.svg'));
                    }
                    &.file {
                        @include background($image: img('file_neutro.svg'));
                    }
                }
                .name {
                    @include display-flex();
                    @include flex-direction(column);
                    width: 380px;
                    max-width: 380px;
                    overflow: hidden;

                    .category {
                        @include font-size(md);
                        font-family: $text-bold;
                        color: $color-black;
                    }
                    .path {
                        @include font-size(11px);
                        font-family: $text-medium;
                        color: $color-black;
                        b {
                            @include font-size(xs);
                            font-family: $text-bold;
                        }
                    }
                }

                .status {
                    @include display-flex();
                    @include justify-content();
                    @include flex-direction(column);
                    width: 100%;
                    height: 100%;

                    .programed {
                        @include font-size(xs);
                        color: $color-neutral-600;
                    }
                    .estate {
                        background-color: $color-neutral-300;
                        padding: 0px 6px 0px 6px;
                        border-radius: 4px;

                        span {
                            padding-left: 8px !important;
                            line-height: 24px !important;
                        }
                    }
                }
                .capitalize::first-letter {
                    text-transform: uppercase;
                }
                .employee-img {
                    @include background($size: cover);
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }
                .employee-name {
                    @include text-ellipsis();
                    display: inline-block;
                    width: calc(100% - 24px);
                    padding-left: 6px;
                    color: $color-black;

                    &.pending {
                        color: $color-warning-800;
                    }
                }

                .category {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: start;
                    align-items: center;

                    .estate {
                        @include background($color: $color-neutral-200);
                        @include display-flex();
                        @include align-items(center);
                        width: max-content;

                        .final-name {
                            @include display-flex();

                            margin: 6px 0;
                            min-height: 2rem;
                            height: auto;
                            width: auto;
                            text-align: center;
                            border-radius: 5px;
                            padding: 6px 12px;
                            font-family: $text-bold;
                            &.parent {
                                color: $color-neutral-600;
                            }
                        }
                        .autocomplete-bold {
                            @include font-size(md);
                            font-family: $text-bold;
                            color: $color-black;
                        }
                        .autocomplete {
                            @include font-size(md);
                            font-family: $text-medium;
                            color: $color-neutral-600;
                            font-weight: normal;
                        }
                        .parent {
                            @include font-size(md);
                            font-family: $text-medium;
                            color: $color-neutral-600;
                        }
                    }
                }
                .ball {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                }
                .ball-color {
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    margin-left: 3px;
                    position: relative;
                    left: 5px;

                    &.active {
                        @include background($color: $color-success-500);
                    }
                    &.inactive {
                        @include background($color: $color-neutral-600);
                    }
                    &.pendent {
                        @include background($color: $color-warning-500);
                    }
                    &.programmed {
                        @include background($color: $color-neutral-300);
                    }
                }
                .locations {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                }
            }
        }

        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }
}
</style>
