<template>
    <div id="content" class="library-management">
        <div class="bar-actions"></div>

        <div class="form-container">
            <div class="form">
                <div class="title-type">{{ $t('library.type_selector_title') }}</div>
                <div class="boxes">
                    <div class="box-container">
                        <div
                            class="box"
                            @click="type('image')"
                            :style="{
                                backgroundImage: 'url(' + css_image_url + 'image_neutro.svg)',
                                backgroundSize: '58px'
                            }"
                        ></div>
                        <div class="text">{{ $t('library.image') }}</div>
                    </div>
                    <div class="box-container">
                        <div
                            class="box"
                            @click="type('video')"
                            :style="{
                                backgroundImage: 'url(' + css_image_url + 'video_neutro.svg)',
                                backgroundSize: '58px'
                            }"
                        ></div>
                        <div class="text">{{ $t('library.video') }}</div>
                    </div>

                    <div class="box-container">
                        <div
                            class="box"
                            @click="type('file')"
                            :style="{
                                backgroundImage: 'url(' + css_image_url + 'file_neutro.svg)',
                                backgroundSize: '58px'
                            }"
                        ></div>
                        <div class="text">{{ $t('library.file') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'TypeSelector',
    components: {},
    data() {
        return {
            statusLibrary: undefined,
            css_image_url: '/../../img/'
        }
    },
    computed: {},
    methods: {
        load() {},

        goBack() {
            this.$emit('back')
        },
        type(type) {
            this.$emit('type', type)
        }
    },
    created() {
        this.load()
    },
    mounted() {},
    watch: {},
    updated() {}
}
</script>

<style lang="scss">
#content.library-management {
    .title-type {
        @include font-size(lg);
        @include display-flex();
        @include justify-content();
    }
    .boxes {
        @include display-flex();
        @include justify-content(center);

        .box-container {
            @include display-flex();
            @include flex-direction(column);

            .text {
                @include align-self(center);
                @include font-size(md);
            }
        }

        .box {
            @include border-radius();
            @include background($color: $color-neutral-200);
            width: 120px;
            height: 120px;
            margin: 60px;
            margin-bottom: 20px;
            cursor: pointer;
        }
    }
}
</style>
