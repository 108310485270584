<template>
    <div class="site-limit">
        <!-- :class="{ error: template.errors && template.errors.codes && template.errors.codes.includes('empty_roles') }" :no-data-text="$t('supervise.filter.no_results')" -->
        <v-autocomplete
            :disabled="locationLimit != false && mode == TYPE.LOCATIONS"
            hide-details
            class="select"
            v-model="mode"
            :items="modes"
            @change="resetEntities()"
            item-text="name"
            item-value="value"
            attach
            dense
        >
        </v-autocomplete>
        <v-autocomplete
            v-if="mode == TYPE.GROUPS"
            hide-details
            class="select"
            v-model="entities"
            :items="Object.values(availableGroupsList)"
            @change="sendData()"
            :placeholder="$t('template.general.site_limit.search_group')"
            item-text="name"
            item-value="id"
            multiple
            attach
            dense
            return-object
            :no-data-text="$t('supervise.filter.no_results')"
        >
            <template v-slot:selection="{ item, index }" v-if="entities.length > 0">
                {{ index == 0 ? '' : '' }}
            </template>
        </v-autocomplete>
        <v-autocomplete
            v-else-if="mode == TYPE.LOCATIONS && type != 2"
            hide-details
            class="select"
            v-model="entities"
            :items="Object.values(availableLocationsList)"
            @change="sendData()"
            :placeholder="$t('template.general.site_limit.search_location')"
            item-text="name"
            item-value="id"
            multiple
            attach
            dense
            return-object
            :no-data-text="$t('supervise.filter.no_results')"
        >
            <template v-slot:selection="{ item, index }" v-if="entities.length > 0">
                {{ index == 0 ? '' : '' }}
            </template>
        </v-autocomplete>
        <Tags
            v-if="entities"
            :backImage="mode == TYPE.LOCATIONS ? 'location_inactive_s30.svg' : 'menu_inactive_s30.svg'"
            :tags="entities"
            :nameTag="'name'"
            :availableEntities="mode === TYPE.GROUPS ? availableGroupsList : availableLocationsList"
            value="id"
            :removable="true"
        ></Tags>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'

export default {
    components: {
        Tags
    },
    props: {
        type: '',
        locations: { default: [] },
        groups: { default: [] },
        locationLimit: { default: false },
        groupLimit: { default: false },
        definedMode: { default: '' } //REQUIRED
    },
    data() {
        return {
            // TYPE GUIDE
            TYPE: {
                GROUPS: 1,
                LOCATIONS: 2
            },
            mode: '',
            entities: []
        }
    },
    computed: {
        availableGroupsList() {
            var result = { ...this.$store.getters['groups/getListTable']() }
            var groupLimitList = this.groupLimit ? [...this.groupLimit] : false

            if (groupLimitList) {
                Object.keys(result).forEach(function (item) {
                    if (!groupLimitList.includes(item)) delete result[item]
                })
            }
            return result
        },
        availableLocationsList() {
            var result = { ...this.$store.getters['location/getListTable']() }
            var locationLimitList = this.locationLimit ? [...this.locationLimit] : false
            var groupLimitList = this.groupLimit ? [...this.groupLimit] : false

            // Si la entidad superior tiene un bloqueo por grupos, cargamos las localizaciones de esos grupos
            if (groupLimitList) {
                for (var group_index in groupLimitList) {
                    var group = { ...this.$store.getters['groups/getList'](groupLimitList[group_index]) }
                    if (group.locations && group.locations != {}) {
                        locationLimitList = Object.keys(group.locations).concat(locationLimitList)
                    }
                }
            }
            if (locationLimitList) {
                Object.keys(result).forEach(function (item) {
                    if (!locationLimitList.includes(item)) delete result[item]
                })
            }
            return result
        },

        groupsList() {
            return { ...this.$store.getters['groups/getList']() }
        },

        locationsList() {
            return { ...this.$store.getters['location/getListTable']() }
        },

        modes() {
            return [
                { name: this.$t('analytics.groups'), value: this.TYPE.GROUPS },
                { name: this.$t('analytics.locations'), value: this.TYPE.LOCATIONS }
            ]
        }
    },
    methods: {
        load() {
            this.entities = []

            // IF LOCATIONS
            if (this.locations.length > 0) {
                this.mode = this.TYPE.LOCATIONS
                var aLocations = []
                var locations = this.locations ? this.locations.split(',') : []
                locations.forEach(function (location) {
                    aLocations.push({ id: location })
                })
                var resultLocations = []
                for (var location_index in aLocations) {
                    var location = aLocations[location_index]
                    resultLocations.push(this.locationsList[location.id])
                }
                this.entities = resultLocations
            }
            // IF GROUPS
            else if (this.groups.length > 0) {
                this.mode = this.TYPE.GROUPS

                var aGroups = []
                var groups = this.groups ? this.groups.split(',') : []
                groups.forEach(function (group) {
                    aGroups.push({ id: group })
                })
                var resultGroups = []
                for (var group_index in aGroups) {
                    var group = aGroups[group_index]
                    resultGroups.push(this.groupsList[group.id])
                }
                this.entities = resultGroups
            } else if (this.locationLimit) {
                this.mode = this.TYPE.LOCATIONS
            } else this.mode = this.definedMode
        },

        resetEntities() {
            this.entities = []
            this.sendData()
        },
        sendData() {
            var data = { groups: '', locations: '', mode: this.mode }
            var result = []
            // IF GROUPS
            if (this.mode == this.TYPE.GROUPS) {
                if (this.entities.length > 0) {
                    for (var group_index in this.entities) {
                        if (this.entities[group_index] != undefined) {
                            var group = this.entities[group_index]
                            result.push(group.id)
                        }
                    }
                    result = result.join()
                } else result = ''
                data.groups = result
            }
            // IF LOCATIONS
            else if (this.mode == this.TYPE.LOCATIONS) {
                if (this.entities.length > 0) {
                    for (var location_index in this.entities) {
                        if (this.entities[location_index] != undefined) {
                            var location = this.entities[location_index]
                            result.push(location.id)
                        }
                    }
                    result = result.join()
                } else result = ''

                data.locations = result
            }

            this.$emit('setSiteLimit', data)
        }
    },
    created() {
        var self = this
        this.$store.dispatch('groups/loadListTable', { status: 1 }).then(function () {
            self.$store.dispatch('location/loadListTable', { status: 1 }).then(function () {
                self.load()
            })
        })
    },

    watch: {
        locations: {
            handler: function (val, oldVal) {
                this.load()
            },
            deep: true
        },
        groups: {
            handler: function (val, oldVal) {
                this.load()
            },
            deep: true
        },
        entities() {
            this.sendData()
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.site-limit {
    @include display-flex();
    @include align-items(flex-start);
    @include justify-content(flex-start);
    @include font-size(sm);
    @include flex-direction(column);
    font-family: $text-light;
    color: $color-neutral-600;
    width: 100%;

    .select {
        margin-bottom: 5px;
        @include justify-content(flex-start);
        width: 100%;
    }

    .description {
        @include font-size(xs);
        font-family: $text;
        color: $color-neutral-600;

        b {
            font-family: $text-bold;
            @include font-size(xs);
            color: $color-neutral-600;
        }
    }
}
</style>
