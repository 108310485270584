<template>
    <div class="filter-box" :class="{ 'empty-results': isDisabled }">
        <div class="title">{{ $t('assets.filter.filter_data') }}</div>
        <div class="filter-items">
            <!-- Name -->
            <div class="filter">
                <label>{{ $t('library.name') }}</label>
                <input
                    :class="{
                        filtered: currentFilters.name && Object.values(currentFilters.name).length > 0,
                        changed: currentFilters.name && currentFilters.name != activeFilters.name
                    }"
                    type="text"
                    hide-details
                    class="input"
                    v-model="currentFilters.name"
                    @keyup.enter="applyFilters()"
                />
            </div>

            <!-- Category -->
            <div class="filter">
                <label>{{ $t('library.category') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.category && Object.values(currentFilters.category).length > 0,
                        changed: JSON.stringify(currentFilters.category) != JSON.stringify(activeFilters.category)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.category"
                    :items="Object.values(optionsCategories)"
                    :placeholder="$t('assets.filter.filter_by')"
                    item-text="name"
                    item-value="id"
                    multiple
                    attach
                    auto-select-first
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>

                    <template v-slot:item="{ active, item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                            <v-list-item-action>
                                <v-checkbox :input-value="active"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title class="title-list" :class="{ parent: item.name.includes('>') }">
                                    <span class="parent" v-if="!item.name.includes('>')">{{ item.parent_id ? getParentCategory(item.parent_id).name + ' > ' : '' }}</span>
                                    <span class="autocomplete-bold">{{ item.name }}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <!-- <div class="list-select" v-html="item.name"></div> -->
                </v-autocomplete>
                <Tags :tags="currentFilters.category" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>

            <!-- start and end date -->
            <div class="filter date">
                <label>{{ $t('library.date') }}</label>
                <v-select
                    :class="{
                        filtered: currentFilters.type_date,
                        changed: JSON.stringify(currentFilters.type_date) != JSON.stringify(activeFilters.type_date)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.type_date"
                    :items="optionsDate"
                    item-text="name"
                    item-value="value"
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    @change="currentFilters.type_date == 'custom' ? (currentFilters.date = {}) : true"
                ></v-select>

                <div class="custom" v-if="currentFilters.type_date == 'custom'">
                    <div class="description">{{ $t('assets.filter.from') }}:</div>
                    <input class="date-input start" v-model="currentFilters.date.start_date" :max="minDate" type="date" @change="calcDate()" />

                    <div class="separator"></div>

                    <div class="description">{{ $t('assets.filter.to') }}:</div>
                    <input class="date-input end" v-model="currentFilters.date.end_date" :min="maxDate" type="date" @change="calcDate()" />
                </div>
            </div>

            <!-- highlight -->
            <div
                class="filter demora"
                :class="{
                    filtered: currentFilters.highlight,
                    changed: JSON.stringify(currentFilters.highlight) != JSON.stringify(activeFilters.highlight)
                }"
            >
                <label>{{ $t('library.highlight_filter') }}</label>
                <input class="switch-input" type="checkbox" v-model="currentFilters.highlight" />
                <div class="description">{{ $t('library.highlight_description') }}</div>
            </div>

            <!-- status -->
            <div class="filter">
                <label>{{ $t('sites.table_headers.state') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.status && Object.values(currentFilters.status).length > 0,
                        changed: JSON.stringify(currentFilters.status) != JSON.stringify(activeFilters.status)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.status"
                    :items="Object.values(optionsStatus)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.status" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>
        </div>

        <FilterActions :showClearFiltersButton="showClearFiltersButton" :clearFiltersCallback="resetFilters" :filterCallback="applyFilters" :changesDetected="changesDetected" />
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'
import FilterActions from '../../ui/FilterActions.vue'

export default {
    name: 'FilterResources',
    components: {
        Tags,
        FilterActions
    },
    props: {
        isDisabled: { type: Boolean, default: false }
    },
    data() {
        return {
            currentFilters: {},
            emptyFilters: {
                status: [{ id: 1, name: i18n.t('tools.groups.status.active') }],
                type_date: 'noDate'
            },
            activeFilters: {},

            // Filter variables
            changesDetected: false,
            maxDate: true,
            minDate: true
        }
    },

    computed: {
        optionsCategories() {
            var categories = JSON.parse(JSON.stringify(this.$store.getters['library/getCategoriesForm']()))
            var resultCategories = []
            for (var category in categories) {
                var idx = categories[category]
                if (idx.parent_id) {
                     idx.name = categories[idx.parent_id].name + ' > ' + idx.name
                }
                resultCategories.push(idx)
            }
            return resultCategories.sort((a, b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            })
        },
        optionsStatus() {
            return this.$store.getters['library/getStatusResourcesFilters']()
        },
        optionsDate() {
            return [
                { value: 'today', name: i18n.t('supervise.filter.today') },
                { value: 'yesterday', name: i18n.t('supervise.filter.yesterday') },
                { value: 'week', name: i18n.t('supervise.filter.week') },
                { value: 'month', name: i18n.t('supervise.filter.month') },
                { value: 'quarter', name: i18n.t('supervise.filter.quarter') },
                { value: 'noDate', name: i18n.t('supervise.filter.noDate') },
                { value: 'custom', name: i18n.t('supervise.filter.custom') }
            ]
        },
        showClearFiltersButton() {
            return JSON.stringify(this.activeFilters) != JSON.stringify(this.emptyFilters)
        }
    },

    methods: {
        getParentCategory(id) {
            if (typeof id === 'string') {
                var category = Object.values({
                    ...this.$store.getters['library/getParentsCategoryFilters']()
                })
                if (category) {
                    for (var cat in category) {
                        var idx = category[cat]
                        if (idx) {
                            if (idx.id == id) {
                                return idx
                            }
                        }
                    }
                }
            }
            return null
        },
        applyFilters() {
            this.$overlay.loading()
            let definitiveFilters = {}

            // name
            definitiveFilters.name = this.currentFilters.name ? this.currentFilters.name : ''

            // categories
            if (this.currentFilters.category) {
                var filter = this.currentFilters.category
                definitiveFilters.category = []
                for (var index in filter) {
                    definitiveFilters.category.push(filter[index].id)
                }
                definitiveFilters.categories = definitiveFilters.category.toString()
            } else {
                definitiveFilters.categories = ''
            }

            // start and end date
            if (this.currentFilters.type_date) {
                if (this.currentFilters.type_date != 'custom') {
                    definitiveFilters.start_date = this.currentFilters.type_date
                    definitiveFilters.end_date = ''
                } else {
                    definitiveFilters.start_date = moment(this.currentFilters.date.start_date).format('YYYY-MM-DD')
                    definitiveFilters.end_date = moment(this.currentFilters.date.end_date).format('YYYY-MM-DD')
                }
            } else {
                definitiveFilters.start_date = ''
                definitiveFilters.end_date = ''
            }

            // highlight
            definitiveFilters.highlight = this.currentFilters.highlight ? 1 : 0
            if (definitiveFilters.highlight === 0) {
                definitiveFilters.highlight = ''
            }

            // status
            if (this.currentFilters.status) {
                var filter = this.currentFilters.status
                definitiveFilters.status = []
                for (var index in filter) {
                    definitiveFilters.status.push(filter[index].id)
                }
                definitiveFilters.status = definitiveFilters.status.toString()
            } else {
                definitiveFilters.status = ''
            }

            let self = this
            if (JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)) {
                this.$emit('changes')
            } else {
                this.$emit('noChanges')
            }
            this.changesDetected = false

            this.$store.commit('library/setFiltersLibraryActive', this.currentFilters)
            this.$store
                .dispatch('library/loadLibrary', {
                    name: definitiveFilters.name,
                    categories: definitiveFilters.categories,
                    start_date: definitiveFilters.start_date,
                    end_date: definitiveFilters.end_date,
                    highlight: definitiveFilters.highlight,
                    status: definitiveFilters.status
                })
                .then(function () {
                    self.activeFilters = JSON.parse(JSON.stringify(self.currentFilters))
                    self.$overlay.hide()
                    self.$emit('resetPageDatatable')
                })
        },

        calcDate() {
            this.currentFilters.start_date ? (this.maxDate = moment(this.currentFilters.start_date).format('YYYY-MM-DD')) : true
            this.currentFilters.end_date ? (this.minDate = moment(this.currentFilters.end_date).format('YYYY-MM-DD')) : true
            // Force update object
            this.currentFilters = { ...this.currentFilters }
        },

        resetFilters() {
            var self = this
            this.$overlay.loading()
            this.currentFilters = { ...this.emptyFilters }
            this.activeFilters = { ...this.emptyFilters }
            this.$store.commit('library/setFiltersLibraryActive', this.currentFilters)
            this.changesDetected = false
            this.$store.dispatch('library/loadLibrary', { status: 1, start_date: 'noDate' }).then(function () {
                self.$overlay.hide()
                self.$emit('resetPageDatatable')
            })
        }
    },
    created() {},
    mounted() {
        var translated = this.$store.getters['library/getFiltersLibraryActive']
        for (var name in translated.status) {
            var index = translated.status[name]
            if (index.name) {
                index.name = i18n.t(index.name)
            }
        }
        this.activeFilters = {
            ...translated
        }
        this.currentFilters = {
            ...translated
        }
        this.emptyFilters = {
            ...translated
        }
        this.applyFilters()
    },
    watch: {
        currentFilters: {
            handler: function (val, oldVal) {
                this.changesDetected = JSON.stringify(this.currentFilters) != JSON.stringify(this.activeFilters)
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
.title-list {
    @include font-size(sm);
    font-family: $text-bold;
    color: $color-black;
    .autocomplete-bold {
        @include font-size(sm);
        font-family: $text-bold;
        color: $color-black;
    }
    .autocomplete {
        @include font-size(sm);
        font-family: $text-medium;
        color: $color-neutral-600;
        font-weight: normal;
    }
    .parent {
        @include font-size(sm);
        font-family: $text-medium;
        color: $color-neutral-600;
    }
}
// Busca en assets :^)
</style>
