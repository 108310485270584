<template>
    <div id="content" class="library-management">
        <template v-if="typeof id == 'undefined' && !hideSelector">
            <typeSelector @type="type" @back="closeForm"></typeSelector>
        </template>

        <template v-else>
            <!-- TODO Pasar este snackbar al componente por favor -->
            <div class="snackbar-container">
                <transition name="fade">
                    <div class="deleted-resource" v-if="resourceDeleted">
                        <span class="text">
                            {{ $t('library.deleted_resource') }}
                        </span>
                        <span class="undo" @click="undoResource()">{{ $t('library.undo') }}</span>
                        <span class="delete" @click="closeUndo()"></span>
                    </div>
                </transition>
            </div>
            <!-- TODO Pasar este snackbar al componente por favor -->

            <div class="bar-actions">
                <!-- TODO INTEGRATE translationLangs with -> library.translations -->
                <TranslateButton :entityId="'library.' + library.id" :translationLangs="library.translations" @newTranslation="newTranslation" @deleteTranslation="deleteTranslation"></TranslateButton>
                <Button bType="cancel" :bCallback="goBack" bLabel="user.form.cancel"></Button>
                <Button bType="save" :bCallback="saveLibraryCategory" customClass="btn-action" :bLabel="$route.params.id ? 'menu_aux.action.save' : 'menu_aux.action.save'"></Button>
                <Button v-if="$route.params.id" bType="more" :bCallback="showMoreOptions"></Button>
                <div class="more-options" v-if="moreOptions">
                    <Button bType="delete" :bCallback="showConfirmDeleteResourceModal" bLabel="menu_aux.action.delete" customClass="btn-secondary"></Button>
                </div>
            </div>

            <div class="form-container">
                <div class="form">
                    <div class="form-title resource">
                        <h1 class="form-title">{{ $t('user.form.basic_info') }}</h1>
                    </div>
                    <!-- Resource type IMAGE -->
                    <div class="label-container" v-if="typeSelected == 'image'">
                        <div class="fullwidth-container">
                            <h2 class="label-form">{{ $t('library.resource_attached') }}</h2>

                            <p v-if="emptyAnswer.noResource" class="error-form error-field" v-html="$t('library.no_resource')"></p>
                            <p v-if="typeSelected == 'image'" class="description" v-html="$t('library.image_selected')"></p>
                            <p v-if="typeSelected == 'video'" class="description" v-html="$t('library.video_selected')"></p>

                            <p v-if="emptyAnswer.recursos_max" class="error-form error-field" v-html="$t('library.max_number_files')"></p>
                            <div class="user-answer resources">
                                <div class="resource-container">
                                    <!-- Subida de imágenes -->
                                    <input type="file" autocomplete="off" style="display: none" name="file" ref="add_resource_library" accept="image/*" multiple @click="nullFiles()" @change="createImage($event, 'add_resource_library')" />
                                    <Button bType="upload" :bCallback="uploadResourceImage" bLabel="library.upload_image" customClass="btn-clear"> </Button>
                                    <!-- Preview de imágenes -->
                                    <div class="resource-img" ref="img_resource_img">
                                        <div v-if="resourcePreview" v-for="(resource, index) in resourcePreview" :key="index" class="resource">
                                            <!-- <div class="resource"> -->
                                            <span class="delete-image" @click="deleteResourceImg(index)"></span>
                                            <div class="image" v-if="isUrl(resource.img)">
                                                <cld-image class="image" :publicId="resource.img" type="fetch" loading="lazy">
                                                    <cld-transformation height="300" radius="3" crop="fill" fetchFormat="auto" />
                                                </cld-image>
                                            </div>
                                            <span class="image" :style="{ backgroundImage: 'url(' + resource.img + ')' }" v-else></span>
                                            <span class="text" v-if="resource.title">{{ resource.title }}</span>
                                            <span v-else class="text">{{ resource.img.split('/')[resource.img.split('/').length - 1].slice(14) }}</span>
                                            <!-- </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Resource type VIDEO -->
                    <div class="label-container translate-hide" v-if="typeSelected == 'video' || typeSelected == 'file'">
                        <div class="fullwidth-container">
                            <h2 class="label-form">{{ $t('library.resource_attached') }}</h2>
                            <p class="description" v-if="typeSelected == 'video'" v-html="$t('library.video_selected')"></p>
                            <p v-if="emptyAnswer.noResource" class="error-form error-field" v-html="$t('library.no_resource')"></p>
                            <p class="description" v-if="typeSelected == 'file'" v-html="$t('library.file_selected')"></p>

                            <p v-if="emptyAnswer.file_max" class="error-form error-field" v-html="$t('library.only_one_file')"></p>
                            <p v-if="id != 'undefined' && resource.length > 0" class="snackbar-form" v-html="$t('library.file_upload_preview')"></p>

                            <div class="user-answer resources">
                                <div class="video-container">
                                    <!-- Subida de vídeo -->

                                    <input v-if="typeSelected == 'video' && !hasLink" type="file" autocomplete="off" style="display: none" name="file" ref="add_resource_video" accept="video/mp4" @click="nullFiles()" @change="createVideo($event, 'add_resource_video')" />
                                    <Button v-if="typeSelected == 'video' && !hasLink" bType="upload" :bCallback="uploadResourceVideo" bLabel="library.upload_file" customClass="btn-clear" :class="{ disabled: library.file.length > 0 && hasLink }"> </Button>
                                    <input v-if="typeSelected == 'file'" type="file" autocomplete="off" style="display: none" name="file" ref="add_resource_file" accept="application/pdf, application/vnd.ms-excel,.zip,.rar,.xlsm,.xlsx" @click="nullFiles()" @change="createVideo($event, 'add_resource_video')" />
                                    <Button v-if="typeSelected == 'file' && resource.length == 0 && (library.file == null || (library.file && library.file.length == 0))" bType="upload" :bCallback="uploadResourceFile" bLabel="library.upload_file" customClass="btn-clear" :class="{ disabled: library.file && library.file.length > 0 }"> </Button>
                                    <!-- Input de enlace video -->
                                    <div class="text" v-if="typeSelected == 'video' && resource.length == 0 && !hasLink && library.file.length == 0">
                                        {{ $t('library.or') }}
                                    </div>

                                    <input v-if="typeSelected == 'video' && !hasLink" type="text" v-model="resourceVideo" class="input w50" autocomplete="off" name="name" :placeholder="$t('library.video_placeholder')" />

                                    <Button
                                        v-if="typeSelected == 'video' && !hasLink"
                                        bType="normal"
                                        :bCallback="uploadLinkVideo"
                                        bLabel="library.upload_link"
                                        customClass="btn-secondary"
                                        :class="{
                                            disabled: resourceVideo == ''
                                        }"
                                    >
                                    </Button>
                                    <div class="attachment" v-if="resource.length > 0 || (library.file && library.file.length > 0 && (hasLink || typeSelected != 'video'))">
                                        <a
                                            :class="{
                                                disabled: id != 'undefined' && resource.length > 0
                                            }"
                                            class="text"
                                            :href="library.file.length > 0 ? (library.file[0].img ? library.file[0].img : library.file[0]) : resource[0]"
                                            target="_blank"
                                            >{{ typeSelected == 'video' ? $t('library.default_attach_video') : $t('library.default_attach_file') }}</a
                                        >
                                        <span class="delete" @click="deleteVideo()"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="label-container spaced translate-hide">
                        <div class="left-container">
                            <!-- miniatura de imágenes -->
                            <h2 class="label-form">{{ $t('library.miniature') }}</h2>
                            <p v-if="typeSelected == 'image'" class="description" v-html="$t('library.miniature_desc')"></p>
                            <div class="user-answer resources">
                                <div class="miniature-changes" v-if="miniature">
                                    <input type="file" id="miniature_selector" style="display: none" ref="miniature_resource_selector" accept="image/*" @change="createImage($event, 'miniature_resources_selector')" />

                                    <!-- <div
                                    v-if="miniature"
                                    class="miniature"
                                    :style="{
                                        backgroundImage: 'url(' + miniature + ')'
                                    }"
                                ></div> -->

                                    <div class="miniature" v-if="miniature && isUrl(miniature[0])">
                                        <cld-image class="miniature" :publicId="miniature[0]" type="fetch" loading="lazy">
                                            <cld-transformation height="300" radius="3" crop="fill" fetchFormat="auto" />
                                        </cld-image>
                                    </div>
                                    <div class="miniature" v-else>
                                        <img :src="miniature" alt="" />
                                    </div>

                                    <Button bType="edit" :bCallback="selectMiniature" bLabel="product.change_icon" customClass="btn-secondary"></Button>
                                    <Button bType="delete" :bCallback="deleteMiniature" bLabel="menu_aux.action.delete" customClass="btn-secondary"></Button>
                                </div>
                                <div class="miniature-changes" v-else>
                                    <input type="file" id="miniature_selector" style="display: none" ref="add_miniature_resource_selector" accept="image/*" @change="createImage($event, 'add_miniature_resource_selector')" />
                                    <div class="miniature-resource" ref="miniature_img_resource"></div>
                                    <div v-if="!miniature" class="miniature-resource-no-image"></div>
                                    <Button bType="upload" :bCallback="addMiniature" bLabel="library.add_miniature"></Button>
                                </div>
                            </div>
                        </div>
                        <div class="right-container">
                            <!-- SWITCH HERE PLS -->
                            <div class="switch">
                                <h2 class="label-form" v-html="statusLibrary ? $t('sites.form.active') : $t('sites.form.inactive')"></h2>
                                <div class="switch-click" @click="popupStatus()">
                                    <input class="switch-input-form" type="checkbox" v-model="statusLibrary" disabled="true" />
                                </div>
                            </div>
                            <p class="description" v-html="$t('library.switch_desc')"></p>
                        </div>
                    </div>
                    <div class="label-container">
                        <div class="left-container w85">
                            <h2 class="label-form">{{ $t('user.user_name') }}</h2>
                            <p v-if="emptyAnswer.name" class="error-form error-field" v-html="$t('library.form.error_name')"></p>
                            <TranslateInput :translation_keyname="'library.' + library.id + '.name'" type="text" v-model="library.name" _class="input w85 custom-width" autocomplete="off" name="name" :_placeholder="$t('product.name_placeholder')"></TranslateInput>
                        </div>
                        <div class="right-container translate-hide">
                            <h2 class="label-form">{{ $t('library.form.description') }}</h2>
                            <TranslateInput :translation_keyname="'library.' + library.id + '.description'" type="text" autocomplete="off" v-model="library.description" _class="input w85" :_placeholder="$t('library.form.description_placeholder')" name="surname"></TranslateInput>
                        </div>
                    </div>
                    <div class="label-container full">
                        <h2 class="label-form">
                            {{ $t('library.category') }}
                        </h2>
                        <p v-if="emptyAnswer.category_id" class="error-form error-field" v-html="$t('library.error_category')"></p>
                        <div class="user-answer select w92">
                            <v-select hide-details class="select" :items="Object.values(optionsCategories)" v-model="library.category_id" item-text="name" item-value="id" :placeholder="$t('library.category_placeholder')" dense :no-data-text="$t('supervise.filter.no_results')" single-line :return-object="false"> </v-select>
                        </div>
                    </div>
                    <div class="label-container">
                        <div class="left-container">
                            <!-- SWITCH HERE PLS -->
                            <div class="switch">
                                <h2 class="label-form" v-html="$t('library.highlight_label')"></h2>
                                <div class="switch-click" @click="highlight()">
                                    <input class="switch-input-form main" type="checkbox" v-model="highlightLibrary" disabled="true" />
                                </div>
                            </div>
                            <p class="description" v-html="$t('library.highlight_desc')"></p>
                        </div>
                        <div class="right-container translate-hide">
                            <div class="header-limitation">
                                <div class="header-text">
                                    <div class="with-switch">
                                        <h2 class="label-form">{{ $t('template.general.site_limit.title') }}</h2>
                                        <div class="switch">
                                            <input class="switch-input-form" type="checkbox" v-model="siteLimit" />
                                        </div>
                                    </div>
                                    <p class="description" v-html="$t('product.product_limit')"></p>
                                    <p v-if="emptyAnswer.emptySite" class="error-form error-field" v-html="$t('product.empty_site')"></p>
                                </div>
                            </div>
                            <div class="user-answer">
                                <SiteLimit :definedMode="limitmode ? limitmode : ''" v-if="siteLimit" @setSiteLimit="setSiteLimit" :locations="locationsSave" :groups="groupsSave"></SiteLimit>
                            </div>
                        </div>
                    </div>
                    <div class="label-container translate-hide">
                        <div class="left-container">
                            <div class="switch">
                                <h2 class="label-form" v-html="$t('library.download')"></h2>
                                <input class="switch-input-form main" type="checkbox" v-model="download" />
                            </div>
                        </div>
                        <div class="right-container">
                            <!-- SWITCH HERE PLS -->
                            <div class="switch">
                                <h2 class="label-form" v-html="$t('library.disponibility_limited')"></h2>
                                <input class="switch-input-form main" type="checkbox" v-model="disponibilitySwitch" />
                            </div>
                            <p class="description" v-html="$t('library.disponibility_desc')"></p>
                            <div class="dates" v-if="disponibilitySwitch">
                                <div class="user-answer dates">
                                    <h2 class="label-form">{{ $t('library.start_date') }}</h2>
                                    <input type="date" v-model="library.start_date" class="input w85" autocomplete="off" name="email" :placeholder="$t('sites.form.email_placeholder')" />
                                </div>
                                <div class="user-answer dates">
                                    <h2 class="label-form">{{ $t('library.end_date') }}</h2>
                                    <p v-if="emptyAnswer.end_date" class="error-form error-field" v-html="$t('library.error_end_date')"></p>
                                    <input type="date" v-model="library.end_date" class="input w85" name="end_date" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import i18n from '@/i18n'
import typeSelector from '@/components/domain/library/typeSelector'
import SiteLimit from '@/components/ui/SiteLimit'

export default {
    name: 'LibraryManagement',
    props: { id: undefined },
    components: { typeSelector, SiteLimit },
    data() {
        return {
            clearTimeout: '',
            timeoutSnackbar: 5000,
            resourceFileBackup: [],
            backupResource: [],
            resourceDeleted: false,
            disponibilitySwitch: false,
            resourceVideo: '',
            miniatureFile: [],
            statusLibrary: undefined,
            highlightLibrary: undefined,
            download: 0,
            resource: [],
            miniature: false,
            resourcePreview: [],
            hasLink: false,
            library: {
                name: '',
                category_id: '',
                file: [],
                highlight: null,
                preview: [],
                start_date: null,
                end_date: null,
                upload_date: null,
                status: '1'
            },
            typeSelected: '',
            hideSelector: false,
            sendForm: true,
            emptyAnswer: {
                name: false,
                status: false,
                category_id: false,
                date_end: false,
                recursos_max: false,
                file_max: false,
                noResource: false
            },
            form: false,
            moreOptions: false,
            sites: 'allLocals',
            postDate: 'inmediately',
            groupsSave: '',
            locationsSave: '',
            siteLimit: false,
            limitmode: '',
            optionsCategories: []
        }
    },
    computed: {
        categoriesRecursive() {
            return this.$store.getters['library/getCategoriesRecursive']
        }
        // optionsCategories() {
        //     var list = Object.values({
        //         ...this.$store.getters['library/getCategoriesForm']()
        //     })
        //     var listSorted = []
        //     if (list) {
        //         for (var category in list) {
        //             var idx = list[category]
        //             if (idx) {
        //                 // if (idx.parent_id) {
        //                 //     // if (!idx.name.includes('>')) {
        //                 //     //     idx.name = this.getParentCategory(idx.parent_id).name + ' > ' + idx.name
        //                 //     // }
        //                 // }
        //                 listSorted.push(idx)
        //             }
        //         }

        //         listSorted = listSorted.sort((a, b) => a.name.localeCompare(b.name))
        //         return listSorted
        //     }
        // }
    },
    methods: {
        // Define la función recursiva para generar los breadcrumbs
        parseCategories(categories, parentCategory = null) {
            const result = []
            for (const categoryId in categories) {
                const category = categories[categoryId]
                // if the category is the same as the current category, skip it to avoid infinite recursion
                if (category.id == this.id) {
                    continue
                }
                const categoryName = category.name
                const categoryIdString = category.id
                const categoryFullName = parentCategory ? `${parentCategory} > ${categoryName}` : categoryName
                result.push({ name: categoryFullName, id: categoryIdString })
                if (category.subcategories) {
                    const subcategories = this.parseCategories(category.subcategories, categoryFullName)
                    result.push(...subcategories)
                }
            }
            return result
        },
        newTranslation(code) {
            this.$set(this.library.translations, code, { missing: 0, translated: 0 })
        },

        deleteTranslation(code) {
            this.$delete(this.library.translations, code)
        },

        setSiteLimit(data) {
            this.groupsSave = data.groups
            this.locationsSave = data.locations
            this.limitmode = data.mode
            if (data.mode) {
                this.sitelimit = true
            }
        },
        showMoreOptions() {
            // METHOD TO SHOW OR HIDE MORE OPTION BUTTONS
            this.moreOptions = !this.moreOptions
        },
        nullFiles() {
            if (typeof this.$refs.add_resource_file != 'undefined') {
                this.$refs.add_resource_file.value = null
            } else if (typeof this.$refs.add_resource_video != 'undefined') {
                this.$refs.add_resource_video.value = null
            }
        },
        // Logic for image resource
        deleteResourceImg(index) {
            var index2 = index - (this.resourcePreview.length - this.resource.length)
            var files = ''
            this.backupResource = []
            this.backupResource.push(this.resourcePreview[index])
            if (this.resource.length > 0) {
                var fileToSave = index - (this.resourcePreview.length + 1 - (this.resource.length + 1))
                this.resourceFileBackup.push(this.resource[fileToSave])

                // this.resourceFileBackup.push(this.resource[0]);
            }
            this.resourcePreview.splice(index, 1)
            if (index2 >= 0) {
                this.resource.splice(index2, 1)
            }

            this.library.file = []
            this.resourcePreview.forEach((file) => {
                files += files != '' ? '|' + file.img : file.img
                this.library.file.push(file)
            })
            this.initializeSnackbar()
        },
        initializeSnackbar() {
            clearTimeout(this.clearTimeout)
            this.resourceDeleted = true
            var self = this
            this.clearTimeout = setTimeout(() => (self.resourceDeleted = false), this.timeoutSnackbar)
        },
        addMiniature() {
            this.$refs.add_miniature_resource_selector.click()
        },
        uploadResourceImage() {
            this.$refs.add_resource_library.click()
        },
        deleteMiniature() {
            this.miniatureFile = []
            this.miniature = false
        },
        selectMiniature() {
            this.$refs.miniature_resource_selector.click()
        },
        // CREATION THUMB
        createImage(e, name) {
            var self = this

            // Comprobar que no se van a añadir + de 10 ficheros o que la suma de los que hay más los que se van a añadir tampoco sea mayor de 10
            if ((e.target.files.length <= 10 && this.resourcePreview.length + e.target.files.length <= 10 && this.resource.length <= 10 + e.target.files.length) || name == 'add_miniature_resource_selector' || name == 'miniature_resources_selector') {
                this.emptyAnswer.recursos_max = false
                var files = e.target.files // FileList object
                var output = this.$refs.img_resource_img
                if (name == 'add_resource_library') {
                    var i = 0
                    for (var file in files) {
                        var idx = files[file]
                        if (typeof idx === 'object') {
                            if (i == 0 && !this.miniature) {
                                this.miniatureFile.push(idx)
                            }
                            this.resource.push(idx)
                            i++
                        }
                    }
                } else if (name == 'add_miniature_resource_selector' || name == 'miniature_resources_selector') {
                    this.miniatureFile = []

                    for (var file in files) {
                        var idx = files[file]
                        if (typeof idx === 'object') {
                            this.miniatureFile.push(idx)
                        }
                    }
                }

                for (var i = 0; i < files.length; i++) {
                    const file = files[i]
                    // Only pics
                    if (!file.type.match('image')) continue
                    if (name == 'add_resource_library') {
                        var picReader = new FileReader()
                        picReader.addEventListener('load', function (event) {
                            var picFile = event.target
                            self.resourcePreview.push({
                                img: picFile.result,
                                title: file.name
                            })
                            if (!self.miniature) {
                                self.miniature = picFile.result
                            }
                            var files = ''
                            self.resourcePreview.forEach((file) => {
                                files += files != '' ? '|' + file.img : file.img
                            })
                            self.$store.commit('library/setCurrentResourceFiles', files)
                        })
                    } else if (name == 'add_miniature_resource_selector' || name == 'miniature_resources_selector') {
                        output = this.$refs.miniature_img_resource
                        var picReader = new FileReader()
                        picReader.addEventListener('load', function (event) {
                            var picFile = event.target
                            self.miniature = picFile.result
                        })
                    }

                    // Read the image
                    picReader.readAsDataURL(file)
                }
            } else {
                this.emptyAnswer.recursos_max = true
            }
        },
        // END LOGIC RESOURCE IMAGE

        // LOGIC FOR VIDEO AND FILE RESOURCE
        uploadResourceVideo() {
            this.$refs.add_resource_video.click()
        },
        uploadResourceFile() {
            this.$refs.add_resource_file.click()
        },

        uploadLinkVideo() {
            this.resource[0] = this.resourceVideo
            // this.library.file[0] = {img:this.resourceVideo}
            this.hasLink = true
        },
        createVideo(e, name) {
            var self = this

            // Comprobar que no se van a añadir + de 1 fichero o que la suma de los que hay más los que se van a añadir tampoco sea mayor de 1
            if (event.target.files.length <= 1) {
                this.emptyAnswer.recursos_max = false
                var files = event.target.files //FileList object
                var output = this.library.file
                var i = 0
                for (var i = 0; i < files.length; i++) {
                    const file = files[i]
                    self.resourcePreview.push({ img: file.name })
                    self.library.file = [{ img: file.name }]
                    self.resource.push(file)
                }
                self.hasLink = true
            } else {
                this.emptyAnswer.recursos_max = true
            }
        },
        deleteVideo() {
            this.resourceDeleted = true
            this.backupResource.push(this.resourcePreview[0])
            if (this.resource.length > 0) {
                this.resourceFileBackup.push(this.resource[0])
            }
            this.resourcePreview = []
            this.resourceVideo = []
            this.resource = []
            this.library.file = []
            this.hasLink = false
            this.initializeSnackbar()
        },
        closeUndo() {
            this.resourceDeleted = false
        },
        undoResource() {
            if (this.typeSelected == 'video' || this.typeSelected == 'file') {
                this.resourceVideo.push(this.backupResource[0])
                this.resourcePreview.push(this.backupResource[0])
                this.library.file.push(this.backupResource[0])
                if (this.resourceFileBackup.length > 0) {
                    this.resource.push(this.resourceFileBackup[0])
                }
                this.backupResource = []
                this.resourceFileBackup = []
                this.hasLink = true
                this.resourceDeleted = false
            } else {
                this.library.file.push(this.backupResource[0])
                this.resourcePreview.push(this.backupResource[0])
                if (this.resourceFileBackup.length > 0) {
                    this.resource.push(this.resourceFileBackup[0])
                }
                this.resourceFileBackup = []
                this.backupResource = []
                this.resourceDeleted = false
            }
        },

        type(type) {
            this.typeSelected = type
            this.hideSelector = true
        },
        closeForm() {
            this.$overlay.show()
            this.$emit('back')
        },
        getParentCategory(id) {
            var category = this.$store.getters['library/getParentsCategoryFilters']()
            if (category) {
                for (var cat in category) {
                    var idx = category[cat]
                    if (idx) {
                        if (idx.id == id) {
                            return idx
                        }
                    }
                }
            }
            return null
        },
        onlyNumbers(event) {
            let val = event.target.value.trim()
            // It can only be positive integer or empty, and the rule can be modified according to the requirement.
            if (/^[0-9]\d*$|^$/.test(val)) {
                this.oldNum = val
            } else {
                event.target.value = ''
            }
        },
        setColor(color) {
            this.color = color
        },

        popupStatus() {
            var selfThis = this
            if (selfThis.statusLibrary == '1' || selfThis.statusLibrary == true) {
                selfThis.$popup.confirm({
                    message: selfThis.$t('library.popup_inactive'),
                    textSave: selfThis.$t('library.popup_inactive_confirm'),
                    callSave: function () {
                        selfThis.statusLibrary = false
                    },
                    callCancel: function () {
                        selfThis.statusLibrary = true
                    }
                })
            } else {
                selfThis.statusLibrary = true
            }
        },
        highlight() {
            var selfThis = this
            if (this.highlightLibrary == '1' || this.highlightLibrary == true) {
                this.highlightLibrary = false
            } else {
                this.highlightLibrary = '1'
            }
            this.$forceUpdate()
        },

        load() {
            var self = this
            if (this.$route.name == 'Library') {
                if (typeof this.id !== 'undefined') {
                    this.$router.push({
                        name: 'EditLibrary',
                        params: { id: this.id }
                    })
                } else {
                    self.$store.commit('library/setCurrentResource', {})
                    this.$router.push({ name: 'AddLibrary' })
                }
            }
            if (this.id) {
                self.$store.commit('library/setCurrentResource', self.$store.getters['library/getLibrary'](this.id))
                var library = {
                    ...this.$store.getters['library/getCurrentResource']
                }
                if (library) {
                    this.statusLibrary = library.status
                    if (library.status == '-2') {
                        this.statusLibrary = false
                    } else if (library.status == '1') {
                        this.statusLibrary = true
                    }
                    this.highlightLibrary = library.highlight
                    if (library.highlight == '0' || library.highlight == null || library.highlight == 'null') {
                        this.highlightLibrary = false
                    }
                    if (Object.values(library.locations).length > 0 || Object.values(library.groups).length > 0) {
                        this.siteLimit = true
                        var locations = Object.values(library.locations)
                        var groups = Object.values(library.groups)
                        var locAux = []
                        var groupsAux = []
                        if (groups.length > 0) {
                            for (var index in groups) {
                                if (groups[index] != undefined) {
                                    groupsAux.push(groups[index].id)
                                }
                            }
                            this.groupsSave = groupsAux.toString()
                        }
                        if (locations.length > 0) {
                            for (var index in locations) {
                                if (locations[index] != undefined) {
                                    locAux.push(locations[index].id)
                                }
                            }
                            this.locationsSave = locAux.toString()
                        }
                    }
                    if (library.start_date) {
                        library.start_date = moment(library.start_date).format('YYYY-MM-DD')
                        this.disponibilitySwitch = true
                    }
                    if (library.end_date) {
                        library.end_date = moment(library.end_date).format('YYYY-MM-DD')
                        this.disponibilitySwitch = true
                    }
                    if (library.preview) {
                        library.preview = [library.preview]
                        this.miniature = library.preview
                    } else {
                        library.preview = []
                    }
                    if (library.type) {
                        if (library.type == '1') {
                            this.typeSelected = 'image'
                            if (library.file) {
                                var fileSplit = library.file
                                this.resourcePreview = []
                                fileSplit.forEach((file) => {
                                    this.resourcePreview.push(file)
                                })
                            } else {
                                library.file = []
                            }
                        } else if (library.type == '2') {
                            if (library.file) {
                                var fileSplit = library.file
                                this.resourcePreview = []
                                fileSplit.forEach((file) => {
                                    this.resourcePreview.push(file)
                                })
                                this.hasLink = true
                                this.resourceVideo = library.file
                            } else {
                                library.file = []
                            }

                            this.typeSelected = 'video'
                        } else if (library.type == '3') {
                            this.typeSelected = 'file'
                            var fileSplit = library.file
                            this.resourcePreview = []
                            if (fileSplit) {
                                fileSplit.forEach((file) => {
                                    this.resourcePreview.push(file)
                                })
                            }
                            this.resourceVideo = library.file
                        }
                    }

                    this.download = library.download
                }

                this.library = library
            } else {
                this.statusLibrary = 1
            }
        },

        goBack() {
            this.$overlay.show()
            this.$emit('back')
        },
        saveLibraryCategory() {
            var self = this
            self.$overlay.loading()
            this.sendForm = true
            // set all values of errors to false
            this.emptyAnswer = _.mapValues(this.emptyAnswer, () => false)
            if (this.library.name === '') {
                this.sendForm = false
                this.emptyAnswer.name = true
            }
            if (this.disponibilitySwitch == false) {
                this.library.start_date = null
                this.library.end_date = null
            }
            var haveCategory = false
            for (var category in this.optionsCategories) {
                var idx = this.optionsCategories[category]
                if (idx.id == this.library.category_id) {
                    haveCategory = true
                }
            }
            if (!haveCategory) {
                this.sendForm = false
                this.emptyAnswer.category_id = true
            }
            if (this.library.category_id === '' || this.library.category_id.length == 1) {
                this.sendForm = false
                this.emptyAnswer.category_id = true
            }
            if ((this.resource.length == 0 && this.library.file.length == 0) || (this.resourcePreview.length == 0 && this.typeSelected == 'image')) {
                this.sendForm = false
                this.emptyAnswer.noResource = true
            }

            // dates validation
            if (this.library.start_date && this.library.end_date) {
                if (moment(this.library.end_date).isSameOrAfter(this.library.start_date, 'day')) {
                } else {
                    this.sendForm = false
                    this.emptyAnswer.end_date = true
                }
            } else if (this.library.end_date) {
                if (!moment(this.library.end_date).isSameOrAfter(moment(), 'day')) {
                    this.sendForm = false
                    this.emptyAnswer.end_date = true
                }
            }

            this.library.status = this.statusLibrary
            if (this.library.status == true) {
                this.library.status = '1'
            } else if (this.library.status == false || this.library.status == undefined) {
                this.library.status = '-2'
            }
            this.library.auxStatus = this.library.status
            this.library.highlight = this.highlightLibrary
            if (this.library.highlight == true) {
                this.library.highlight = '1'
            } else {
                this.library.highlight = '0'
            }

            // if (this.library.preview[0] != this.miniature) {
            //   this.library.preview = [];
            //   this.library.preview = this.miniatureFile;
            //   this.library.status = 2;
            // }
            if (!this.miniature) {
                if (this.library.preview.length > 0) {
                    this.library.preview = []
                    // this.library.status = 2
                }
            } else {
                if (this.library.preview.length == 0 || this.library.preview[0] != this.miniature) {
                    this.library.preview = []
                    this.library.preview = this.miniatureFile
                    this.library.status = 2
                }
            }
            // Site limit
            if (this.siteLimit) {
                if (this.locationsSave != '') {
                    this.library.locations = this.locationsSave
                } else if (this.groupsSave != '') {
                    this.library.groups = this.groupsSave
                    if (this.library.locations != '') {
                        this.library.locations = []
                    }
                } else if (this.locationsSave == '' && this.groupsSave == '') {
                    this.emptyAnswer.emptySite = true
                    this.sendForm = false
                    this.library.groups = this.groupsSave
                    this.library.locations = this.locationsSave
                    this.locationsSave = this.locationsSave
                    this.groupsSave = this.groupsSave
                }
            } else if (!this.siteLimit) {
                this.groupsSave = ''
                this.locationsSave = ''
                this.library.locations = []
                this.library.groups = []
            }

            if (this.sendForm) {
                var i = this.resourcePreview.length - this.resource.length
                this.library.file.splice(i)
                for (i = 0; i < this.library.file.length; i++) {
                    this.library.file[i] = this.library.file[i].img
                }
                if (this.resource.length > 0) {
                    this.library.file = this.library.file.concat(this.resource)
                    if (this.typeSelected != 'video') {
                        this.library.status = 2
                    } else if (typeof this.resource[0] !== 'string') {
                        this.library.status = 2
                    }
                }
            }

            this.library.download = this.download ? 1 : 0

            if (this.sendForm) {
                if (this.$route.params.id) {
                    this.$store.dispatch('library/editLibrary', this.library).then(function (response) {
                        self.goBack()
                        self.$snackbar.open({
                            message: self.$t('snackbar.edit_success') + ' <b>' + self.library.name + '</b>',
                            customClass: 'success',
                            duration: 5000
                        })
                    })
                } else {
                    if (this.typeSelected == 'image') {
                        this.library.type = '1'
                    } else if (this.typeSelected == 'video') {
                        this.library.type = '2'
                    } else if (this.typeSelected == 'file') {
                        this.library.type = '3'
                    }
                    this.$store.dispatch('library/addLibrary', this.library).then(function (response) {
                        //   alert('COMPLETE GO BACK!')
                        self.$snackbar.open({
                            message: self.$t('snackbar.add_success') + ' <b>' + self.library.name + '</b>',
                            customClass: 'success',
                            duration: 5000
                        })
                        self.goBack()
                    })
                }
            } else {
                self.$overlay.hide()
                scrollError()
            }
        },

        deleteResource() {
            var self = this

            const snackbarData = {
                message: self.$t('snackbar.remove_success') + ' <b>' + self.library.name + '</b>',
                customClass: 'second',
                duration: 5000
            }

            self.$store
                .dispatch('library/deleteLibrary', {
                    id: self.library.id
                })
                .then(function (response) {
                    self.$emit('back')
                    self.$snackbar.open(snackbarData)
                })
        },

        showConfirmDeleteResourceModal() {
            var self = this

            const modalMessage = self.$t('library.popup_delete') + self.library.name + "'?"

            this.$popup.delete({ message: modalMessage, callSave: this.deleteResource })
        },

        removeExtensionFromName(str) {
            const lastDot = str.lastIndexOf('.')

            if (lastDot !== -1) {
                return str.substring(0, lastDot)
            } else {
                return str
            }
        },
        isUrl(img) {
            //Check if is minitaure is a url like http:// or https://
            const regex = new RegExp('^(http|https)://', 'i')
            return regex.test(img)
        }
    },

    watch: {
        resourcePreview: function () {
            // if the resource uploaded is just one
            if (this.library.name === '') {
                if (this.resourcePreview.length === 1 && this.resourcePreview[0].title) {
                    // update the resource name input field with the name of the single file img(removing its extension):
                    this.library.name = this.removeExtensionFromName(this.resourcePreview[0].title)
                } else if (this.resourcePreview.length === 1 && !this.resourcePreview[0].title) {
                    // update the resource name input field with the name of the single file document(removing its extension):
                    this.library.name = this.removeExtensionFromName(this.resourcePreview[0].img)
                    // if the library has zero or more than one file, input field is empty, so user has to complete it:
                } else this.library.name = ''
            }
        }
    },

    created() {
        this.load()
        this.selected = []
    },
    mounted() {
        if (Object.keys(this.categoriesRecursive).length > 0) {
            //get categories
            this.optionsCategories = this.parseCategories(this.categoriesRecursive)
            // sort categories
            this.optionsCategories = this.optionsCategories.sort((a, b) => a.name.localeCompare(b.name))
        }
    },
    updated() {}
}
</script>

<style lang="scss">
#content.library-management {
    // position: relative; <- Si ponemos esto se ve mal el scroll

    .form-container {
        // overflow: unset;
        // height: auto;
        // padding-top: 80px;

        .with-switch {
            @include display-flex();

            .label-form {
                padding-right: 10px;
            }
        }
    }
    .left-container {
        padding-left: 0;
    }

    .resource-container {
        width: 100%;
    }

    .resource-img {
        $resource-size: 120px;
        @include display-flex();
        @include flex-wrap(wrap);
        @include flex-direction(row);
        width: 100%;

        .resource {
            margin: 18px 24px 6px 0;
            width: $resource-size;
            height: auto;
            position: relative;
            cursor: pointer;

            .delete-image {
                @include background($color: $color-neutral-200, $image: img('close_main_s10.svg'), $size: 12px);
                @include border-radius(50%);
                position: absolute;
                width: 24px;
                height: 24px;
                right: -6px;
                top: -6px;
                transition-duration: 0.2s;

                &:hover {
                    background-color: $color-neutral-600;
                }
            }
            .image {
                @include border-radius(4px);
                //@include background($color: $color-neutral-200, $size: cover);
                display: block;
                width: 100%;
                height: $resource-size;
                background-size: cover;
                img {
                    max-width: 100%;
                    object-fit: cover;
                    height: 100%;
                }
            }
            .text {
                @include text-ellipsis($line: 3);
                @include font-size(sm);
                font-family: $text-medium;
                color: $color-black;
                width: 100%;
                margin-top: 6px;
                word-break: break-word;
            }
        }
    }
    .video-container {
        @include display-flex();
        @include align-items(center);
        width: 100%;

        input {
            margin-left: 20px;
        }
        button {
            width: 180px;
            margin-right: 20px;
        }
    }
    .dates {
        @include display-flex();
        padding-left: 10px;
    }
    .miniature {
        @include background($size: cover);
        @include border-radius(4px);
        @include display-flex();
        width: 110px;
        height: 110px;
        img {
            max-width: 100%;
            object-fit: cover;
        }
    }
    .miniature-changes {
        @include display-flex();

        button {
            @include align-self(center);
        }
    }
    .miniature-resource-no-image {
        @include background($color: $color-neutral-200, $size: cover, $image: img('image_neutro.svg'));
        width: 120px;
        height: 120px;
        margin-right: 30px;
    }

    .attachment {
        @include display-flex();
        @include border-radius(4px);
        @include background($color: $color-neutral-200);
        width: fit-content;
        height: 30px;
        padding: 6px;

        .text {
            @include font-size(sm);
            color: $color-primary-500;
            text-decoration: underline;
            display: inline-block;
            width: calc(100% - 18px - 6px);
            cursor: pointer;
        }

        .delete {
            @include border-radius(50%);
            @include background($color: $color-neutral-600, $image: img('close_main_ffffff.svg'), $position: 4px 4px, $size: 10px);
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-left: 6px;
            cursor: pointer;
        }
    }
    .my-gallery {
        display: flex;
    }
}
</style>
